'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import { useDefaultUrl } from './index'
import { useEffect, useRef } from 'react'
import ClipboardJS from 'clipboard'
import { useBoolean } from 'react-use'
import useToast from '@hooks/useToast'

export default function ShareLink({
  url
}) {

  const { shareUrl, selfRef } = useShareLink({ url })
  return (
    <button type='button' ref={selfRef} className='block'>
      <DisablePrefetchLink href={shareUrl} className='hidden'>Share link</DisablePrefetchLink>
      <i className='icon icon-link !text-3xl'></i>
    </button>
  )
}

export function ShareLinkText({
  url
}) {

  const { shareUrl, selfRef, success } = useShareLink({ url })
  return (
    <button type='button' ref={selfRef} className='block'>
      <DisablePrefetchLink href={shareUrl} className='hidden'>Share link</DisablePrefetchLink>
      <i className='icon icon-link !text-md'></i>
      <span className='text-sm text-font ml-8 sm:text-base'>Copy link</span>
      {
        success && (<span className='text-green ml-8'>ok</span>)
      }
    </button>
  )
}

function useShareLink({ url }) {

  const shareUrl = useDefaultUrl(url)
  const selfRef = useRef(null)
  const [success, toggleSuccess] = useBoolean(false)
  const { successMessage } = useToast()

  useEffect(() => {

    if (!selfRef.current) return

    const clipboard = new ClipboardJS(selfRef.current, {
      text: () => shareUrl
    })
    clipboard.on('success', () => {
      successMessage('Copy success', { variant: 'success' })
      toggleSuccess(true)
    })

    return () => {
      clipboard.destroy()
    }
  }, [selfRef, shareUrl, toggleSuccess, successMessage])


  return {
    shareUrl,
    selfRef,
    success
  }
}