import { houseDetail } from '@router'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import OfficeLine from '../office/line'
import Cover from '@component/cover'
import { concat, first, get } from 'lodash-es'
import { joinStr } from '@utils'
import Avatar from '@component/avatar'
import HouseFacility from '../facility'

export default function ListingsArticle({
  data
}) {

  const cover = first(concat(data.images, data.floorPlanImages))
  const agent = get(data, 'agents.0')

  return (
    <div className='shadow-card rounded-sm overflow-hidden group'>
      <div className='grid grid-cols-1 sm:grid-cols-2'>
        <Cover src={cover} alt={data.teaser} hoverScale width={330} height={216} defaultIcon='icon-house-cover'></Cover>
        <div className='p-16 sm:p-24 flex flex-col justify-between items-start gap-y-10'>
          <div className='w-full flex items-center justify-between gap-x-10'>
            <address className='text-font text-lg font-normal not-italic'>{joinStr([data.street, data.suburb], ', ')}</address>
            {
              agent && <Avatar src={agent.icon} width={48} height={48} circle alt={agent.name}></Avatar>
            }
          </div>
          <div className='text-font text-base font-normal'>{joinStr([data.priceBold, data.priceLight], ' ')}</div>
          <HouseFacility data={data}></HouseFacility>
          <DisablePrefetchLink href={houseDetail(data)} className='button-primary-out rounded-4xl w-full !no-underline sm:w-auto' target='_blank'>View listings page<i className='icon icon-arrow-right ml-6 !text-sm'></i></DisablePrefetchLink>
        </div>
      </div>
      <OfficeLine data={data} className='!h-40' iconHeight={30}></OfficeLine>
    </div>
  )
}

export function SkeletonCard() {
  return (
    <div className='skeleton-wrap space-y-10'>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-10'>
        <div className='bone h-[20vh] max-h-200 sm:aspect-video sm:h-auto'></div>
        <div className='flex flex-col gap-y-10 justify-between'>
          <div className='flex items-center gap-x-10'>
            <div className='bone flex-1 h-30'></div>
            <div className='bone w-48 h-48 !rounded-half'></div>
          </div>
          <div className='bone h-20 w-2/3'></div>
          <div className='bone h-16 w-1/2 !hidden sm:!block'></div>
          <div className='bone h-16 w-1/3 !hidden sm:!block'></div>
        </div>
      </div>
      <div className=' boneh-30'></div>
    </div>
  )
}