import getHouseRoute from '@plugin/searchRoute/modules/house'
import { houseList } from '@router'
import { houseSearchType } from '@routerConfig'
import DisablePrefetchLink from '@component/disablePrefetchLink'

export default function SearchLinkCard() {
  const houseRoute = getHouseRoute()
  houseRoute.pushNewZealand()
  const url = houseList(houseSearchType.residential, houseRoute.toSlug(), { utm_source: 'internal', utm_medium: 'news_article', utm_campaign: 'in_article_search' })

  return (
    <div className="mx-auto bg-white border w-500 max-w-full p-16 flex flex-col md:flex-row md:items-center justify-between gap-x-10 rounded-sm">
      <div className="flex-1 text-font">
        <h2 className="text-2xl">Start your property search</h2>
        <div className="md:mt-10 text-base">Find your dream home today.</div>
      </div>
      <DisablePrefetchLink
        href={url}
        className='button-orange w-full mt-10 md:mt-0 !text-white !no-underline md:!w-100'
        target='_blank'
      >
        Search
      </DisablePrefetchLink>
    </div>
  )
}
