'use client'

import { buildUrl } from '@plugin/http/lib/utils'
import { assign } from 'lodash-es'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { openWindow, useDefaultUrl } from './index'
import IoncLinkedin from '@icon/const/social/linkedin.svg'

export default function ShareLinkedin({
  url,
  title,
  summary
}) {

  url = useDefaultUrl(url)

  // http://www.linkedin.com/shareArticle?
  // mini=true
  // &url=https://stackoverflow.com/questions/10713542/how-to-make-custom-linkedin-share-button /10737122
  // &title=How%20to%20make%20custom%20linkedin%20share%20button
  // &summary=some%20summary%20if%20you%20want
  // &source=stackoverflow.com
  const shareUrl = buildUrl('http://www.linkedin.com/shareArticle', undefined, assign({
    mini: true,
    url
  }, title ? { title } : undefined, summary ? { summary } : undefined))

  function clickHandle(event) {
    event.preventDefault()
    openWindow(shareUrl)
  }
  return (
    <button type='button' onClick={clickHandle} className='block'>
      <DisablePrefetchLink href={shareUrl} className='hidden'>Share linkedin</DisablePrefetchLink>
      <IoncLinkedin width={32} height={32} className='block'></IoncLinkedin>
    </button>
  )
}