'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import ListingsStandard from '@component/card/listings/standard'
import { articleDataLayer } from '@plugin/google/gtm/modules/news'
import { get, map, slice } from 'lodash-es'
import { useCallback } from 'react'

export default function Content({
  title,
  data
}) {


  const changeHandle = useCallback(swiper => {
    const { realIndex } = swiper
    const arr = slice(data, realIndex - 1, realIndex + 3)
    const items = map(arr, (item, idx) => getDataReport(item, idx))

    articleDataLayer.featuredPropertyClick(items)
  }, [data])

  const slideClick = useCallback((item, idx) => {
    articleDataLayer.featuredPropertyClick([getDataReport(item, idx)])
  }, [])

  return (
    <SectionLayout title={title}>
      <SectionSwiper
        data={data}
        Card={ListingsStandard}
        slideClick={slideClick}
        onChange={changeHandle}
        slideClassName='py-12'
        swiperClassName='-my-12'
        cardParams={{ prefetch: false }}
      ></SectionSwiper>
    </SectionLayout>
  )
}

function getDataReport(data, idx) {

  const nzmeInfo = get(data, 'nzmeInfo')
  return {
    item_id: data.id,
    item_name: [data.street, data.suburb_name, data.district, data.region].join(', '),
    index: idx,
    item_category: nzmeInfo && nzmeInfo.type || 'dev_fallback',
    item_list_id: 'chosen_for_you_news_detail',
    item_list_name: 'Recommended listings - article page',
    item_variant: nzmeInfo ? `${nzmeInfo.mv}|${nzmeInfo.rank}` : '',
    location_id: 'Article Page'
  }
}