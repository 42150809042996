import { NEWS_BODY_TAG_TYPE } from '@config'
import { join, map, replace } from 'lodash-es'
import ArticleHouseCard from './house'
import SearchLinkCard from './searchLink'

export default function ArticleBodyContent({
  data
}) {

  const [list, noCard] = getBodyList(data)
  return (
    <div className='article-body text-lg'>
      {
        noCard
          ? <div className='space-y-24 childs:max-w-full childs-[a]:text-primary childs-[a]:underline childs-[*]:max-w-full' dangerouslySetInnerHTML={{ __html: bodyPadProtocol(data.body) || '' }}></div>
          : (
            <div className='space-y-24 childs:max-w-full childs-[a]:text-primary childs-[a]:underline childs-[*]:max-w-full'>
              {
                map(list, (item, idx) => (
                  <Item key={idx} data={item}></Item>
                ))
              }
            </div>
          )
      }

    </div>
  )
}

function Item({
  data
}) {

  switch (data.type) {
    case NEWS_BODY_TAG_TYPE.house:
      return (
        <ArticleHouseCard data={data.value}></ArticleHouseCard>
      )
    case NEWS_BODY_TAG_TYPE.searchLink:
      return (
        <SearchLinkCard />
      )
    case NEWS_BODY_TAG_TYPE.text:
      return <div className='space-y-24' dangerouslySetInnerHTML={{ __html: data.value || '' }}></div>
  }
}

function bodyPadProtocol(body) {
  if (!body) return body
  return replace(body, /href=["'](?!https?:\/\/)(.*?)["']/gi, 'href="https://$1"')
}

export function getBodyList(data) {
  const { body, imageAlt } = data
  let str = `${bodyPadProtocol(body)}`
  // 替换image alt
  if (imageAlt) str = str.replace(/(<img.*?)alt=".*?"/gi, '$1').replace(/(<img)/gi, `$1 alt="${imageAlt}"`)
  str = str.replace(/\r(?:\n)?/g, '<br/>')
  let regAd = /<(div|p)[^>]*>.*?<\/(div|p)>/gi

  let regTag = new RegExp(`<a class="(${join(map(NEWS_BODY_TAG_TYPE, val => val), '|')})"[^>]*>[^<]*?<\/a>`, 'gi')  // 插入标签正则
  let regValue = /data-([^=]*)="([^"]*?)"/gi  // 取值正则
  // 在第4段插入广告
  let strs = str.match(regAd)
  if (strs.length > 4) {
    strs.splice(4, 0, '<a class="search-link-tag">Search link</a><a class="ad-tag" data-ad-size="[[300,250],[\'fluid\']]" data-adtype="rectangle" data-adunit="rectangle" data-adpos="1">Ad Tag</a>')
  }
  if (strs.length > 8) {
    strs.push('<div class="display-on-mobile"><a class="ad-tag" data-ad-size="[[300,250],[\'fluid\']]" data-adtype="rectangle" data-adunit="rectangle" data-adpos="4">Ad Tag</a></div>')
  }
  str = strs.join('')
  let list = []
  let noCard = true
  do {
    var res = regTag.exec(str)
    if (res) {
      noCard = false
      let currStr = res[0]
      let tag = res[1]
      // 存入tag之前的文字
      list.push({
        type: 'text-tag',
        value: str.substring(0, res.index)
      })
      // 删除tag之前的文字
      str = str.substring(res.index + currStr.length)
      // 取值
      let value = {}
      do {
        var val = regValue.exec(currStr)
        if (val) value[val[1]] = replace(val[2], /'fluid'/i, '"fluid"')
      } while (val)
      // 构建渲染数据
      let component = {
        type: tag,
        value
      }
      list.push(component)
      // 重置正则
      regTag.lastIndex = 0
      regValue.lastIndex = 0
    } else {
      list.push({
        type: 'text-tag',
        value: str
      })
    }
  } while (res)
  // 没有标签的情况
  if (list.length === 0) {
    list.push({
      type: 'text-tag',
      value: str
    })
  }

  return [list, noCard]
}