'use client'

import httpClient from '@apiClient'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import useLogin, { useSignupVerify } from '@hooks/useLogin'
import useProgressRouter from '@hooks/useProgressRouter'
import IconLogo from '@icon/const/logo-oneroof.svg?url'
import Bg2 from '@image/article/property-ladder-bg-m.jpg'
import Bg from '@image/article/property-ladder-bg.jpg'
import { URLS, getRouterHref } from '@router'
import { replace, some } from 'lodash-es'
import Image from 'next/image'
import { useCallback } from 'react'

export default function PropertyLadder({
  data
}) {

  const show = (() => {
    let res = false
    let reg = /^(firsthomebuyer)s?$/i
    some(data.tags, item => {
      if (!item.label) return false

      res = reg.test(replace(item.label, /\s/g, ''))
      return res
    })
    return res
  })()

  const url = getRouterHref(URLS.profileCommunications, undefined, { checked: 'firsthomebuyer_news' })
  const { pushRouter } = useProgressRouter()
  const toCommunications = useCallback(() => {
    pushRouter(url)
  }, [pushRouter, url])

  const { isLogin } = useLogin()
  const handleVerify = useSignupVerify(toCommunications, 'FHB_Series', 1)
  const handleSignup = useCallback((event) => {
    if (isLogin) {
      httpClient.post('self-add-source', { signupSource: 'FHB_Series' })
    } else {
      return handleVerify(event)
    }
  }, [handleVerify, isLogin])

  return show && (
    <div className='w-250 h-250 relative max-w-full sm:w-500 sm:h-95 mx-auto'>
      <Image src={Bg} alt='property-ladder-bg' className='hidden sm:block w-full h-full object-cover'></Image>
      <Image src={Bg2} alt='property-ladder-bg2' className='block sm:hidden w-full h-full object-cover'></Image>
      <div className='absolute inset-x-0 inset-y-0 flex flex-col items-center px-15 py-35 sm:px-26 sm:py-10 sm:flex-row'>
        <span className='border h-48 border-solid border-white border-opacity-70 absolute left-15 right-15 pointer-events-none border-b-0 top-17 sm:border-b sm:border-r-0 sm:w-47 sm:h-auto sm:top-10 sm:bottom-10'></span>
        <Image src={IconLogo} alt='logo' width={86} height={52} className='w-86 h-52 pointer-events-none sm:w-60 sm:h-36' unoptimized></Image>
        <div className='text-white flex flex-1 flex-col text-center justify-center whitespace-nowrap sm:mx-10 sm:text-left'>
          <div className='text-2xl font-bold'>The Property Ladder</div>
          <div className='text-sm mt-6'>A first home buyers email series</div>
        </div>
        <DisablePrefetchLink href={url} className='button-orange w-156 sm:w-98 h-30 !text-xs' onClick={handleSignup} {...(isLogin ? {} : { 'data-noprogress': true })}>Sign up</DisablePrefetchLink>
        <span className='border h-48 border-solid border-white border-opacity-70 absolute left-15 right-15 pointer-events-none border-t-0 bottom-17 sm:border-t sm:border-l-0 sm:w-47 sm:h-auto sm:top-10 sm:bottom-10 sm:right-15 sm:left-auto'></span>
      </div>
    </div>
  )
}