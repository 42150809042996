'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import NewsStandard from '@component/card/news/standard'

export default function Content({
  data
}) {

  return (
    <SectionLayout title='Recommended'>
      <SectionSwiper data={data} Card={NewsStandard} cardParams={{ prefetch: false }}></SectionSwiper>
    </SectionLayout>
  )
}