import(/* webpackMode: "eager" */ "/data/web/oneroof-rebuild/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/data/web/oneroof-rebuild/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/data/web/oneroof-rebuild/public/icon/const/video.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/avatar/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/breadcrumb/index.js");
;
import(/* webpackMode: "eager" */ "/data/web/oneroof-rebuild/src/app/_components/cover/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["PageViewLayer"] */ "/data/web/oneroof-rebuild/src/app/_components/dataLayer/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/fromAppHidden/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAdvertPageBase","default"] */ "/data/web/oneroof-rebuild/src/app/_components/google/advert/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/menu/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/share/email.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/share/facebook.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/share/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/share/linkedin.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/_components/share/twitter.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/_modules/searchBar/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/body/house/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/body/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/cover/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/featuredProperty/content.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/propertyLadder/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/recommend/content.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data/web/oneroof-rebuild/src/app/(layoutpage)/(hubpage)/news/[...slug]/@article/_modules/stats/index.js");
