'use client'

import Form from '@component/form'
import FormButton from '@component/form/button'
import FormCheckbox from '@component/form/checkbox'
import FormInput from '@component/form/input'
import { useOpenSigninDialog } from '@component/login/context'
import { useCallback, useState } from 'react'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import getRouter, { URLS } from '@router'
import { assign, isFunction } from 'lodash-es'
import { prospectUser } from '@plugin/login'
import { useMount } from 'react-use'

export default function LoginViewContent({
  title,
  sub,
  buttonText,
  signupSource,
  onSuccess,
  onMount,
  onSignin
}) {

  useMount(() => {
    isFunction(onMount) && onMount()
  })

  const openSigninDialog = useOpenSigninDialog()
  const openSigninHandle = useCallback(() => {
    isFunction(onSignin) && onSignin()
    openSigninDialog()
  }, [openSigninDialog, onSignin])

  const [formData, setFormData] = useState({
    email: '',
    isReceiveBizEmail: 1
  })

  const rules = {
    email: {
      required: true,
      type: 'email',
      message: 'Please enter a valid email address',
      trigger: 'blur'
    }
  }

  const poster = useCallback(async data => {
    await prospectUser(assign({ signupSource }, data))
    isFunction(onSuccess) && onSuccess()
  }, [signupSource, onSuccess])

  return (
    <div className='absolute inset-x-0 inset-y-0 backdrop-blur-sm' >
      <div className='shadow-card bg-white p-32 rounded-sm w-560 mx-auto max-w-full mt-[min(10%,80px)]'>
        <div className='text-center text-xl font-normal'>{title}</div>
        <div className='text-center text-sm text-neutral-5 mt-8'>{sub}</div>
        <Form data={formData} onChange={setFormData} rules={rules} poster={poster} size='sm' className='mt-30'>
          <FormInput prop='email' placeholder='Enter email' prefix={<i className='icon icon-email ml-10 self-center !text-lg'></i>}></FormInput>
          <FormCheckbox prop='isReceiveBizEmail' trueValue={1} falseValse={0}>I would like to receive emails with property info, updates and more from OneRoof.</FormCheckbox>
          <FormButton>{buttonText}</FormButton>
        </Form>
        <div className='text-center mt-30 text-sm'>
          Already have an account?
          <button type='button' className='button-text-primary ml-10' onClick={openSigninHandle}>Sign in</button>
        </div>
        <div className='mt-20 text-center text-xs text-font-secondary2'>
          By providing my email, I agree to OneRoof’s
          <DisablePrefetchLink className='button-text-primary underline ml-6' href={getRouter(URLS.userTerms)}>Terms of use</DisablePrefetchLink>,
          <DisablePrefetchLink className='button-text-primary underline mx-6' href={getRouter(URLS.privacyPolicy)}>Privacy Policy</DisablePrefetchLink>
          and to receive property-related emails from OneRoof.
        </div>
      </div>
    </div >
  )
}