'use client'

import LoginView from '@component/loginView'
import ArticleBodyContent from './content'
import { articleDataLayer } from '@plugin/google/gtm/modules/news'

export default function ArticleBody({
  data
}) {

  return (
    <LoginView
      active={data.loginView}
      signupSource={`article-${data.id}`}
      title='Unlock this free article'
      sub={`This ${data.category} article is for registered OneRoof users. Sign up for free to read this article and much more, including receiving the latest property news, insights and inspiration direct to your inbox.`}
      buttonText='Continue to article'
      onMount={articleDataLayer.loginView.show}
      onSuccess={articleDataLayer.loginView.success}
      onSignin={articleDataLayer.loginView.signin}
    >
      <ArticleBodyContent data={data}></ArticleBodyContent>
    </LoginView>
  )
}
