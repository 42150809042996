"use client"

import ImageBgLoading from '@component/imageBgLoading'
import SectionSwiper from '@component/layout/section/swiper'
import { formatVideoUrl } from '@utils'
import { first, get, size } from 'lodash-es'

export default function ArticleCover({
  data
}) {

  let cover
  if (data.isVideo && get(data, 'video.overseas')) {
    cover = (
      <iframe width='100%' height={400} className='block' src={formatVideoUrl(data.video.overseas)} allowFullScreen></iframe>
    )
  } else if (size(data.images) > 1) {
    cover = (
      <SectionSwiper showNext={false} useNavigation={false} slidesPerView={1} data={data.images} Card={ImageSlide} cardParams={{ subject: data.subject }}></SectionSwiper>
    )
  } else {
    cover = (
      <ImageSlide data={first(data.images) || data.coverImg} subject={data.subject}></ImageSlide>
    )
  }

  return (
    <div>
      {cover}
      {
        data.coverAuthor && (
          <div className='py-16 border-b text-sm break-words'>{data.coverAuthor}</div>
        )
      }
    </div>
  )
}

function ImageSlide({
  data,
  subject
}) {
  return (
    <ImageBgLoading src={data} className='w-full h-auto object-cover block' width={1200} height={788} alt={subject}></ImageBgLoading>
  )
}