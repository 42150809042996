'use client'

import LoginViewContent from './content'
import { useCallback, useEffect, useState } from 'react'

export default function LoginView({
  active = false,
  title,
  sub,
  buttonText,
  signupSource,
  onSuccess,
  onMount,
  onSignin,
  children
}) {

  const [logged, setLogged] = useState(false)
  useEffect(() => {
    setLogged(localStorage.getItem('or-login-view'))
  }, [setLogged])

  const successHandle = useCallback(() => {
    localStorage.setItem('or-login-view', 'y')
    setLogged(true)
    isFunction(onSuccess) && onSuccess()
  }, [onSuccess, setLogged])

  if (!active || logged) return children

  return (
    <div className='relative'>
      <LoginViewContent
        title={title}
        sub={sub}
        buttonText={buttonText}
        signupSource={signupSource}
        onSuccess={successHandle}
        onMount={onMount}
        onSignin={onSignin}
      ></LoginViewContent>
      {children}
    </div>
  )
}