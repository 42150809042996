'use client'

import { buildUrl } from '@plugin/http/lib/utils'
import { assign } from 'lodash-es'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { openWindow, useDefaultUrl } from './index'
import IoncTwitter from '@icon/const/social/twitter.svg'

export default function ShareTwitter({
  url,
  text
}) {

  url = useDefaultUrl(url)

  const shareUrl = buildUrl('https://twitter.com/intent/tweet', undefined, assign({
    text,
    hashtags: 'oneroof,property',
    via: 'oneroofnz',
    url
  }))

  function clickHandle(event) {
    event.preventDefault()
    openWindow(shareUrl)
  }
  return (
    <button type='button' onClick={clickHandle} className='block'>
      <DisablePrefetchLink href={shareUrl} className='hidden'>Share twitter</DisablePrefetchLink>
      <IoncTwitter width={32} height={32} className='block'></IoncTwitter>
    </button>
  )
}