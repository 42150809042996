'use client'

import ShareEmailDialog from '@component/pages/shareEmail'
import { useMemo } from 'react'
import { useBoolean } from 'react-use'
import PropTypes from 'prop-types'
import { getHouseId } from '@utils/house'
import { exitFullscreen } from '@utils'

export default function ShareEmail({
  data,
  type
}) {

  const { clickHandle, dialog } = useShareEmail({ data, type })
  return (
    <>
      <button type='button' onClick={clickHandle} className='block'>
        <i className='icon icon-email !text-3xl font-bold !leading-none'></i>
      </button>
      {dialog}
    </>
  )
}

export function ShareEmailText({
  data,
  type
}) {

  const { clickHandle, dialog } = useShareEmail({ data, type })
  return (
    <>
      <button type='button' onClick={clickHandle} className='flex items-center'>
        <i className='icon icon-email !text-lg font-bold !leading-none'></i>
        <span className='text-sm text-font ml-8 sm:text-base'>Email</span>
      </button>
      {dialog}
    </>
  )
}


function useShareEmail({ data, type }) {

  const url = useMemo(() => {
    if (type === 'house') {
      return `house/${getHouseId(data)}/email`
    } else if (type === 'article') {
      return `news/${data.id}/email`
    } else if (type === 'builder') {
      return `builder/franchise/${data.id}/email`
    }
  }, [data, type])
  const [showDialog, setShowDialog] = useBoolean(false)

  function clickHandle(event) {
    exitFullscreen()
    event.preventDefault()
    setShowDialog(true)
  }

  // ShareEmailDialog
  const dialog = <ShareEmailDialog show={showDialog} url={url} onClose={() => setShowDialog(false)}></ShareEmailDialog>

  return {
    clickHandle,
    dialog
  }
}

ShareEmail.propTypes = {
  type: PropTypes.oneOf(['house', 'article', 'builder'])
}