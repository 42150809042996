'use client'

import ListingsArticle, { SkeletonCard } from '@component/card/listings/acticle'
import useFetch from '@hooks/useFetch'
import { get } from 'lodash-es'

export default function ArticleHouseCard({
  data
}) {

  const id = get(data, 'house-id')
  const { data: house, isLoading } = useFetch({ url: `house/${id}`, params: { soldNum: 0 } })
  return (
    (isLoading || !house)
      ? (
        <SkeletonCard></SkeletonCard>
      )
      : (
        <ListingsArticle data={house}></ListingsArticle>
      )
  )
}
