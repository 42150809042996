import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

export default function useToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const successMessage = useCallback(message => {
    return enqueueSnackbar(message, {
      variant: 'success',
      style: {
        backgroundColor: '#C2F1C9',
        color: 'black',
      },
    })
  }, [enqueueSnackbar])

  const failMessage = useCallback(message => {
    return enqueueSnackbar(message, {
      variant: 'fail',
      style: {
        backgroundColor: '#e1c6cc',
        color: 'black',
      },
    })
  }, [enqueueSnackbar])

  return {
    successMessage,
    failMessage,
    closeToast: closeSnackbar
  }
}