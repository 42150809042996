import { getFullPath } from '@utils'
import { usePathname } from 'next/navigation'

export function useDefaultUrl(url) {
  const pathname = usePathname()

  if (!url) {
    url = getFullPath(pathname)
  } else if (!/^https?:/.test(url)) {
    url = getFullPath(url)
  }
  return url
}

export function openWindow(url, width = 600, height = 420) {
  let winWidth = window.innerWidth || document.documentElement.clientWidth
  let winHeight = window.innerHeight || document.documentElement.clientHeight

  window.open(url, 'sharer',
    'status=no' +
    `,width=${width}` +
    `,height=${height}` +
    `,left=${winWidth / 2 - width / 2}` +
    `,top=${winHeight / 2 - height / 2}` +
    ',toolbar=no' +
    ',location=no' +
    ',menubar=no' +
    ',scrollbars=no' +
    ',resizable=yes')
}