'use client'

import httpClient from '@apiClient'
import AppendRoot from '@component/appendRoot'
import Form from '@component/form'
import FormButton from '@component/form/button'
import FormInput from '@component/form/input'
import FullPopup from '@component/fullPopup'
import useToast from '@hooks/useToast'
import { isFunction } from 'lodash-es'
import { useCallback, useState } from 'react'

export default function ShareEmailDialog({
  show = false,
  url,
  onClose
}) {

  // form data
  const [formData, setFormData] = useState({
    email: '',
    friend: '',
    send: '',
    subject: ''
  })
  const rules = {
    email: {
      required: true,
      type: 'email',
      message: 'Please enter a valid email address',
      trigger: 'blur'
    },
    friend: {
      required: true,
      message: 'Friend’s Name is required',
      trigger: 'blur'
    },
    send: {
      required: true,
      message: 'Your Name is required',
      trigger: 'blur'
    },
    subject: {
      required: true,
      message: 'Comment is required',
      trigger: 'blur'
    }
  }

  const { successMessage } = useToast()
  const poster = useCallback(async () => {
    const res = await httpClient.post(url, formData)
    isFunction(onClose) && onClose()
    successMessage(res.message)
  }, [formData, url, onClose, successMessage])

  return (
    <AppendRoot>
      <FullPopup show={show} smCenter mobileFull contentClassName='!bg-transparent p-16 w-screen h-fill-screen flex items-center justify-center' zIndex={101}>
        {
          show && (
            <div className='w-full bg-white px-16 sm:w-320 sm:h-auto sm:max-w-full sm:px-32 py-20'>
              <div className='text-font text-base font-bold'>Email a friend</div>
              <Form data={formData} rules={rules} onChange={setFormData} poster={poster} className='w-full mt-20'>
                <FormInput prop='email' label='Friend’s Email' required></FormInput>
                <FormInput prop='friend' label='Friend’s Name' required></FormInput>
                <FormInput prop='send' label='Your Name' required></FormInput>
                <FormInput prop='subject' label='Comment' textarea required></FormInput>
                <div className='grid grid-cols-2 items-end gap-x-10'>
                  <FormButton>Submit</FormButton>
                  <button type='button' className='button-primary-out' onClick={onClose}>Cancel</button>
                </div>
              </Form>
            </div>
          )
        }
      </FullPopup>
    </AppendRoot>
  )
}