'use client'

import { statsNewsDetail } from '@plugin/statistics'
import { useMount } from 'react-use'

export default function Stats({
  data
}) {

  useMount(() => {
    statsNewsDetail(data.id)
  })
}