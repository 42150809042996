'use client'

import { buildUrl } from '@plugin/http/lib/utils'
import { assign } from 'lodash-es'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { openWindow, useDefaultUrl } from './index'
import IoncFacebook from '@icon/const/social/facebook.svg'
import { exitFullscreen, isFullscreen } from '@utils'

export default function ShareFacebook({
  url
}) {

  const { shareUrl, clickHandle } = useShareFacebook({ url })

  return (
    <button type='button' onClick={clickHandle} className='block'>
      <DisablePrefetchLink href={shareUrl} className='hidden'>Share facebook</DisablePrefetchLink>
      <IoncFacebook width={32} height={32} className='block'></IoncFacebook>
    </button>
  )
}

export function ShareFacebookText({
  url
}) {

  const { shareUrl, clickHandle } = useShareFacebook({ url })

  return (
    <button type='button' onClick={clickHandle} className='flex items-center'>
      <DisablePrefetchLink href={shareUrl} className='hidden'>Share facebook</DisablePrefetchLink>
      <IoncFacebook width={16} height={16} className='block'></IoncFacebook>
      <span className='text-sm text-font ml-8 sm:text-base'>Facebook</span>
    </button>
  )
}

function useShareFacebook({ url }) {

  const href = useDefaultUrl(url)

  const shareUrl = buildUrl('https://www.facebook.com/dialog/share', undefined, assign({
    app_id: process.env.NEXT_PUBLIC_FACEBOOK_APPID,
    display: 'popup',
    href
  }))

  function clickHandle(event) {
    event.preventDefault()
    if (isFullscreen()) {
      exitFullscreen()
      setTimeout(() => {
        openWindow(shareUrl)
      }, 1000)
    } else {
      openWindow(shareUrl)
    }
  }

  return {
    shareUrl,
    clickHandle
  }
}